import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import tasqFeedbackModule from './tasqFeedbackModule';
import axiosClient from '@/lib/rest/axiosClient';
import uniqid from 'uniqid';
import workflowApolloClient from '@/lib/appsync/workflow';
import getWellStatuses from '@/graphql/workflow/queries/getWellStatuses.graphql';
import Vue from 'vue'
import { getConfigEnv, sleep } from '@/utils/helpers';
import axios from 'axios';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'gptModule',
  store,
})
class GptModule extends VuexModule {
  seeSimilars: any[] = [];
  recommendations: any[] = [];
  forms: any[] = [];
  lastActions: any[] = [];
  wellsAssigneeStatus: any[] = [];
  defaultLastActions: any[] = [];
  activeManualTasqWells: any[] = [];

  wellsWaitingOnStatus: any = [];

  setpointSummary: any = null;


  completedTodoItems: any = []

  pendingTodoItems: any = []

  queryTime: any = 0;

  appLevelNotification: any = {}

  overviewHidden = false;

  gptQueryText = '';

  currentlyViewedActions: any = [];

  gasLiftData: any = null;

  currentlyPendingLastActionComment = '';

  isLoadingActions = false;

  plungerSummary: any = null;

  actionedTasqs: any = [];

  wellDefermentPercentage: any = [];

  isLoadingSummary = false

  loadManualEntries = false

  gptQueryController: any = null

  selectedAiGroupWells: any = [];

  gptAiQueryGroupWells: any = []

  lastestWellsLastActions: any = []


  timeseriesStart: any = null

  timeseriesEnd: any = null


  @Mutation
  updateTimeseriesSearchStartEnd({start,end}): void {
    this.timeseriesStart = start
    this.timeseriesEnd = end
  }

  @Mutation
  updateSelectedAiGroupWells(value): void {
    if (this.selectedAiGroupWells.indexOf(value) > -1) {
      this.selectedAiGroupWells.splice(this.selectedAiGroupWells.indexOf(value), 1);
    } else {
      this.selectedAiGroupWells.push(value);
    }
  }

  selectedAiGroupWellsObj: any = []

  @Mutation
  updateSelectedAiGroupWellsObj(value): void {
    const index =  this.selectedAiGroupWellsObj.findIndex(w => w.wellName === value.wellName)
    if (index > -1) {
      this.selectedAiGroupWellsObj = this.selectedAiGroupWellsObj.filter(w => w.wellName !== value.wellName);
    } else {
      this.selectedAiGroupWellsObj = [...this.selectedAiGroupWellsObj,value,];
    }
  }


  @Mutation
  removeByTypeSelectedAiGroupWellsObj(type): void {
    console.log(this.selectedAiGroupWellsObj);
    this.selectedAiGroupWellsObj = this.selectedAiGroupWellsObj.filter(w => w.typeOfData !== type);
    console.log(this.selectedAiGroupWellsObj);
  }

  animateState =  false


  @Mutation
  setAnimateState(value): void {
    this.animateState = value
  }


  aiGroupSparkLineSelectedSignal ={}

  @Mutation
  setSparklineVal({query,val}): void {
    delete this.aiGroupSparkLineSelectedSignal[query]
    this.aiGroupSparkLineSelectedSignal[query] = val
    // console.log(this.aiGroupSparkLineSelectedSignal);
  }




  @Mutation
  setLastestWellsLastActions(value): void {
    this.lastestWellsLastActions = value
  }



  gptAiGroupFeedback: any = []


  @Mutation
  resetGroupFeedback(): void {
   this.gptAiGroupFeedback = []
  }

  @Mutation
  updateGroupFeedback({wellName, state}): void {
    this.gptAiGroupFeedback = this.gptAiGroupFeedback.filter(w => w.wellName !== wellName);
   this.gptAiGroupFeedback.push({wellName,state})
  }


  @Mutation
  updateGptAiGroupWells(value): void {
    this.gptAiQueryGroupWells =  this.gptAiQueryGroupWells.filter(w => w.wellName !== value);
  }


  @Mutation
  updateGptAiGroupWellsByType(value): void {
    console.log( this.selectedAiGroupWellsObj);
    this.selectedAiGroupWellsObj =  this.selectedAiGroupWellsObj.filter(w => w.typeOfData !== value);
  }


  @Mutation
  addGptAiGroupWellsByType(value): void {
    this.selectedAiGroupWellsObj =  [...this.selectedAiGroupWellsObj,...value]
  }


  @Mutation
  setLoadingSummary(value): void {
    this.isLoadingSummary = value;
  }

  @Mutation
  setLoadManualEntries(value): void {
    this.loadManualEntries = value;
  }

  @Mutation
  setGptAiGroupWells(value): void {
    this.gptAiQueryGroupWells = value;
  }

  @Mutation
  addGptAiGroupWells(value): void {
    this.gptAiQueryGroupWells = [...this.gptAiQueryGroupWells,value,];
  }

  @Mutation
  resetGptAiGroupWells(): void {
    this.gptAiQueryGroupWells = [];
  }


  @Mutation
  setActionedtasqs(value){
    this.actionedTasqs = value;
  }

  @Mutation
  setWellsDefermentPercentage(value){
    this.wellDefermentPercentage = value;
  }


  @Mutation
  setActiveManutalTasqWells(value){
    this.activeManualTasqWells = value;
  }

  lastestDowntimeCodes: any = []

  lastestWellDowntimeCodes: any = []

  @Mutation
  setLastestWellDowntimeCodes(value){
    this.lastestWellDowntimeCodes = value;
  }

  @Mutation
  setLastestDowntimeCodes(value){
    this.lastestDowntimeCodes = value;
  }


  @Mutation
  setCompletedTodoItems(value){
    this.completedTodoItems = value;
  }

  @Mutation
  setPendingTodoItems(value){
    this.pendingTodoItems = value;
  }


  hoveringLastAction: any = null;

  @Mutation
  setHoveringLastAction(value){
    this.hoveringLastAction = value;
  }


  @Mutation
  addItemToPendingTodoList(item){
    let localItem =  item
    if(!localItem.checked){
      this.completedTodoItems = this.completedTodoItems.filter((step) => item.id !== step.id && item.text !== step.text);
      this.pendingTodoItems.push(localItem)
    }else{
      this.completedTodoItems = this.completedTodoItems.filter((step) => item.id !== step.id && item.text !== step.text);
      this.completedTodoItems.push(localItem)
    }
  }


  get getLatestLastAction() {
    return (nodeID: string): any =>
      [...this.lastestWellsLastActions].find((t) => t.nodeid
      ?.toLowerCase() === nodeID?.toLowerCase());
  }


  @Mutation
  setWellsWaitingOnStatus(value){
    this.wellsWaitingOnStatus = value;
  }


  SelectedGroupWellRow: any = null

  @Mutation
  setSelectedGroupWellRow(value){
    this.SelectedGroupWellRow = value;
  }


  get wellDefermentDetails() {
    return (nodeID: string): any =>
      [...this.wellDefermentPercentage].find((t) => t.NodeID === nodeID);
  }


  get activeNodeActionedTasqs() {
    return (nodeID: string): any =>
      [...this.actionedTasqs].find((t) => t.nodeid
      ?.toLowerCase() === nodeID?.toLowerCase());
  }


  get activeManualTasqByWellName() {
    return (nodeID: string): any =>
      [...this.activeManualTasqWells].find((t) => t.node_name
      ?.toLowerCase() === nodeID?.toLowerCase());
  }


  get waitingOnStatusByWellName() {
    return (nodeID: string): any =>
      [...this.wellsWaitingOnStatus].find((t) => t.nodeid
      ?.toLowerCase() === nodeID?.toLowerCase());
  }


  get waitingOnStatusByPadName() {
    return (nodeID: string): any =>
      [...this.wellsWaitingOnStatus].find((t) => t.nodeid
      ?.toLowerCase() === nodeID?.toLowerCase());
  }

  get nodeToDoStatusByName() {
    return (nodeID: string): any =>
      [...this.wellsAssigneeStatus].find((t) => t.wellName
      ?.toLowerCase() === nodeID?.toLowerCase());
  }

  get wellDowntimeCodes() {
    return (nodeID: string): any =>
      [...this.lastestWellDowntimeCodes].find((t) => t.nodeid
       === nodeID);
  }

  @Mutation
  updateWellsStatus(data): void {
    const index = this.wellsWaitingOnStatus.findIndex(
      tasqObject => tasqObject.nodeid == data.nodeid
    );
    if (index > -1) {
      let oldTasq = this.wellsWaitingOnStatus[index]
      Object.keys(oldTasq).forEach(key => oldTasq[key] = null);
      Object.entries(data).forEach(entry => Vue.set(oldTasq, entry[0], entry[1]));
    }else{
      this.wellsWaitingOnStatus.push(data)
    }
  }


  @Mutation
  deleteWellsStatus(nodeid): void {
    const newWellsStatus = this.wellsWaitingOnStatus.filter(
      tasqObject => tasqObject.nodeid !== nodeid
    );
    this.wellsWaitingOnStatus = newWellsStatus
  }


  @Mutation
  setNotfications(value): void {
    this.appLevelNotification = value;
  }

  @Mutation
  setSeeSimilars({ results, queryTime }: { results: any[]; queryTime: any }): void {
    if (results && queryTime === this.queryTime) {
      this.seeSimilars = results.filter(
        (r) =>
          r.resolution &&
          r.resolution.toLowerCase() !== 'no resolution provided' &&
          r.resolution.toLowerCase() !== 'see summary' &&  r.body.toLowerCase() !== 'see summary'
         && r.summary.toLowerCase() !== 'see summary'
      );
    }
  }

  @Mutation
  setRecommendations({ recommendations, queryTime }: { recommendations: any[]; queryTime: any }): void {
    if (recommendations !== null && queryTime === this.queryTime) {
      this.recommendations = recommendations;
    }
  }


  @Mutation
  setGasLiftData(data: any): void {
    this.gasLiftData = data;
  }


  @Mutation
  setForms(data: any[]): void {
    this.forms = data;
  }

  @Mutation
  setSetpointSummary(data: any): void {
    this.setpointSummary = data;
  }

  @Mutation
  setPlungerSummary(data: any): void {
    this.plungerSummary = data;
  }

  @Mutation
  setQueryTime(time): void {
    this.queryTime = time;
  }

  @Mutation
  setGptQueryText(text): void {
    this.gptQueryText = text;
  }

  @Mutation
  setCurrentlyViewedActions(data): void {
    this.currentlyViewedActions = data;
  }

  @Mutation
  setCurrentlyPendingLastActionComment(data): void {
    this.currentlyPendingLastActionComment = data;
  }

  @Mutation
  addNewLastAction(data): void {
    if (this.lastActions.length) {
      this.lastActions.push(data);
    }

    this.defaultLastActions.push(data);
  }

  @Mutation
  setOverviewHidden(data): void {
    this.overviewHidden = data;
  }

  @Mutation
  setDefaultLastActions(data): void {
    this.defaultLastActions = data;
  }

  @Mutation
  setActionsLoading(data): void {
    this.isLoadingActions = data;
  }

  @Mutation
  setLastActions({ results, queryTime }: { results: any[]; queryTime }): void {
    console.log(queryTime);
    if (results && queryTime === this.queryTime) {
      const comments = results['comments'];
      const alarms = results['alarms'];
      const changepoints = results['changepoints'];
      this.lastActions = [...comments, ...alarms, ...changepoints];
    }
  }

  @Mutation
  setLastActionsActive(date): void {
    const updatedActions = this.lastActions.map((action) => {
      if (action.date === date) {
        action.active = true;
      } else {
        action.active = false;
      }
      return action;
    });

    console.log(updatedActions);
    this.lastActions = updatedActions;
  }

  @Mutation
  setLastActionsInActive(): void {
    const updatedActions = this.lastActions.map((action) => {
      action.active = false;
      console.log(action);
      return action;
    });

    console.log(updatedActions);
    this.lastActions = updatedActions;
  }

  @Mutation
  resetSeeSimilars(): void {
    this.seeSimilars = [];
  }

  @Mutation
  updateDefaultLastAction({ lastAction, action }): void {
    const newLastActions = this.defaultLastActions.map((item) => {
      if (action.id === item.id) {
        item = {
          ...lastAction,
          ...action,
        };
      }
      return item;
    });
    this.defaultLastActions = newLastActions;
  }

  @Mutation
  updateLastAction({ lastAction, action }): void {
    const newLastActions = this.lastActions.map((item) => {
      if (action.id === item.id) {
        item = {
          ...lastAction,
          ...action,
        };
      }
      return item;
    });
    this.lastActions = newLastActions;
  }


  @Mutation
  deleteDefaultLastAction(id): void {
    const newLastActions = this.defaultLastActions.filter((item) => id !== item.id);
    this.defaultLastActions = newLastActions;
  }

  @Mutation
  deleteLastAction(id): void {
    const newLastActions = this.lastActions.filter((item) => id !== item.id);
    this.lastActions = newLastActions;
  }

  @Mutation
  resetRecommendations(): void {
    this.recommendations = [];
  }

  @Mutation
  resetForms(): void {
    tasqFeedbackModule.resetAllData();
    this.forms = [];
  }

  @Mutation
  resetLastActions(): void {
    this.lastActions = [];
  }

  @Mutation
  setWellsAssigneeStatus(data): void {
    this.wellsAssigneeStatus = data;
  }

  @Mutation
  addWellsTodoAssignee(data): void {
    const updateOrAddObject = ({ wellName, status,count }) => {
      const foundObject = this.wellsAssigneeStatus.find((item) => item.wellName === wellName);

      if (foundObject) {
        foundObject.activeTodolistForUser = status;
        foundObject.totalActiveItemsCount = count;
      } else {
        this.wellsAssigneeStatus.push({
          wellName: wellName,
          activeTodolistForUser: status,
          totalActiveItemsCount: count
        });
      }
    };
    updateOrAddObject(data);
  }

  @Mutation
  setGptQueryController(value): void {
    if(value === 'null'){
      this.gptQueryController = null
    }else if(value=== 'set') {
      this.gptQueryController =  axios.CancelToken.source();
    }else if(this.gptQueryController  && value === 'abort'){
      console.log('abortControllerSingals was aborted');
      this.gptQueryController.cancel("Canceling previous request due to new request.");
    }
  }

  @Action
  async getGptLastActions(payload) {
    try {
      console.log(payload);
      if (!payload.wellMetaData) {
        return;
      }
      const response = await axiosClient.post('/ai/actions', payload);

      // const { results } = response.data;
      this.setLastActions(response.data);
    } catch (error) {
      console.log(error);
    }
  }



  @Action
  async postWaitingOnComment(payload: any): Promise<any> {
    try {

      const waitingOnPayload = {
        type: 'Waiting on',
        ...payload,
      }
      await this.postGptComment(waitingOnPayload);
      return;
      let endpointURL = `/ai/comments/waiting-on`;

      this.setCurrentlyPendingLastActionComment(payload.body);

      const { data } = await axiosClient.post(endpointURL, payload);
      console.log(data);
      if (data && data.results) {

        const id = data.results.id

        const action = {
          type: 'Waiting on',
          id: id,
          tracking_id: await uniqid(),
          reason: payload.summary,
          summary: payload.summary,
          nodeid: payload.wellMetaData.nodeid,
          body: payload.body,
          date: payload.start_date ||  new Date().getTime(),
          end_date: payload.end_date,
        };
        this.addNewLastAction(action);
        this.setCurrentlyPendingLastActionComment('');

        return action;
      }

      return {}

      console.log('Comment posted successfully');
    } catch (e) {
      this.setCurrentlyPendingLastActionComment('');
      console.log(e);
    }
  }


  @Action
  async postGptComment(payload: any) {
    try {

      const preventAddLastAction: any = payload.preventAddLastAction
      delete payload.preventAddLastAction

      if (!preventAddLastAction) {
        this.setCurrentlyPendingLastActionComment(payload.body);
      }
      const { data } = await axiosClient.post(`/ai/comments`, payload);

      if(preventAddLastAction){
        return;
      }
      console.log(data);
      if (data && data.comment) {
        const comment = data.comment[0];

        const action = {
          type: comment.type,
          id: comment.id,
          reason: comment.reason,
          body: payload.body,
          summary: payload.summary,
          date: payload.start_date ||  new Date().getTime(),
          end_date: data.end_date,
        };
        this.addNewLastAction(action);
        this.setCurrentlyPendingLastActionComment('');

        return action;
      }else if(data && data.results)  {

        const updatedData  = data.results
        const action = {
          type: updatedData.type,
          id: updatedData.id,
          tracking_id: updatedData.tracking_id,
          summary: updatedData.summary,
          sub_category: updatedData.sub_category,
          category: updatedData.category,
          form: updatedData.form,
          fixed_category: updatedData.fixed_category,
          reason: updatedData.summary,
          body: payload.body,
          date: payload.start_date ||  new Date().getTime(),
          end_date: payload.end_date,
          nodeid: updatedData.nodeid
        };
        this.addNewLastAction(action);
        this.setCurrentlyPendingLastActionComment('');

        return action;
      }

      console.log('Comment posted successfully');
    } catch (e) {
      this.setCurrentlyPendingLastActionComment('');
      console.log(e);
    }
  }

  @Action
  async getSetpointSummary(payload) {
    try {
      this.setLoadingSummary(true)
      this.setSetpointSummary(null);
      this.setPlungerSummary(null);
      const { data: setpointSummary }: any = await axiosClient.get('/setpoint-summary', {
        params: payload,
      });

      if (setpointSummary && setpointSummary.data && Object.keys(setpointSummary.data).length) {
       const allData = setpointSummary.data
        if(allData['On Site Fix']){
          this.setPlungerSummary({'On Site Fix': allData['On Site Fix']})
          delete allData['On Site Fix']
        }
        if(Object.keys(allData).length){
          this.setSetpointSummary(allData);
        }

      }
    } catch (error) {
      console.log(error);
    }finally{
      this.setLoadingSummary(false)
    }
  }

  @Action
  async uploadFiles(payload: any) {
    try {
      const { data } = await axiosClient.post('/ai/gas-lift/data', payload);

      console.log(data);
      return data
      console.log('Files posted successfully');
    } catch (e) {
      console.log(e);
    }
  }


  @Action
  async updateGasLiftData({depthValue,depthKey, typeValue, typeKey}) {
    try {
      console.log(this.gasLiftData);
      const data: any = this.gasLiftData.data;
      const localGasLiftData = this.gasLiftData
      if (!data || !data.schematics) return;
      const newGlData = { 'schematics' : []}

    newGlData['schematics'][0] = data.schematics[0].filter(item => !(item[depthKey] === depthValue && item[typeKey] === typeValue))
      const newGasLiftData = {
        ...localGasLiftData,
        data:newGlData,
      }
      this.setGasLiftData(newGasLiftData)
      await axiosClient.put(`/equipment-store/${newGasLiftData._id}`,{data: newGasLiftData.data});
    } catch (e) {
      console.log(e);
    }
  }


  @Action
  async getAllActiveManualTasqs({operatorName}): Promise<any> {
    try {
      const { data }: any = await axiosClient.get('/response-store/manual-tasqs', {
        params: {
          operator: operatorName,
          source_type: 'app',
          value_type: 'manual tasq',
          node_level: 'Well',
        },
      });
      this.setActiveManutalTasqWells(data)

    } catch (e) {
      console.log(e);
    }
  }

  @Action
  async getLastestDowntimeCodes({operatorName}): Promise<any> {
    try {
      const { data }: any = await axiosClient.get('/meta/latest-downtimecodes', {
        params: {
          operator: operatorName,
        },
      });

      const results = data.filter((item) => item.localVal);
      const downtimecodes = results.map((item) => item.localVal);
      const uniqueArray = [...new Set(downtimecodes)];
      this.setLastestDowntimeCodes(uniqueArray)
      this.setLastestWellDowntimeCodes(results)

    } catch (e) {
      console.log(e);
    }
  }


  @Action
  async getActionedTasqsCount({operatorName}): Promise<any> {
    try {
      const { data }: any = await axiosClient.get('/meta/actioned-tasqs-count', {
        params: {
          operator: operatorName,
        },
      });
      this.setActionedtasqs(data)

    } catch (e) {
      console.log(e);
    }
  }


  @Action
  async getOperatorDeferment({operatorName}): Promise<any> {
    try {
      const { data }: any = await axiosClient.get('/deferments/percentage', {
        params: {
          operator: operatorName,
        },
      });
      this.setWellsDefermentPercentage(data)

    } catch (e) {
      console.log(e);
    }
  }

  @Action
  async getWaitingOnStatus({operatorName,nodeID = null}): Promise<any> {
    try {

      if(nodeID){
        await sleep(2000)
        console.log('calling for '+ nodeID);
      }

      const {data} =  await axiosClient.get(`/meta/node-waiting-on-status`,{
        params: {
          ...(nodeID && { node_id: nodeID }),
          operator: operatorName,
          active_waiting_on: true,
        },
      });

      // console.log(data)

      let formattedData: any = []
      if(data && data.length){
        formattedData = data.map(i => {
          return {
            ...i,
            start_date: i.start_date || i.date
          }
        })
      }



     if(nodeID){
      if(formattedData && formattedData.length){
        this.updateWellsStatus(formattedData[0])
      }else{
        this.deleteWellsStatus(nodeID)
      }

    }else{
      if(formattedData && formattedData.length){
      this.setWellsWaitingOnStatus(formattedData)
      }
    }


     return formattedData

    } catch (e) {
      console.log(e);
    }
  }


  @Action
  async getWellStatuses(nodeID = null) {
    try {
      const {
        data: { get_well_statuses: data },
      }: any = await workflowApolloClient.query({
        query: getWellStatuses,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            ...(nodeID && { NodeID: nodeID }),
          },
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.Results) {
        const results = data.Results.map((result) => {
          const status = JSON.parse(result);
          return {
            wellName: status.NodeID,
            totalActiveItemsCount: status.ActiveTodolistCount,
            activeTodolistForUser: status.ActiveTodolistForUser,
            assignedTodoList: status.AssignedTodoList,
          };
        });
       this.setWellsAssigneeStatus(results)
      }


    } catch (e) {
      console.log(e);
     }
  }
}

export default getModule(GptModule);
